import { Brand } from "@/interfaces/brand";
import { ProductCategory } from "@/interfaces/productCategoy";
import { Room } from "@/interfaces/room";
import { Vendor } from "@/interfaces/vendor";
import { i18n } from "@/plugins/i18n";
import { FormField, FormFieldTypes } from "helix-vue-components";

export const productViewSearchForm: (
  rooms: Room[],
  categories: ProductCategory[],
  brands: Brand[],
  vendors: Vendor[]
) => FormField[] = (
  rooms: Room[],
  categories: ProductCategory[],
  brands: Brand[],
  vendors: Vendor[]
) => [
  {
    component: FormFieldTypes.autocomplete,
    label: i18n.t("rooms.view_title"),
    name: "selectedRooms",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 3,
      associationValue: "id",
      associationText: "name",
      selectOptions: () => rooms,
      vuetifyProps: {
        "no-data-text": i18n.t("no_search_results"),
        "item-value": "id",
        "deletable-chips": true,
        "cache-items": true,
        multiple: true,
        chips: true
      }
    }
  },
  {
    component: FormFieldTypes.autocomplete,
    label: i18n.t("categories"),
    name: "categorySelected",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 3,
      associationValue: "id",
      associationText: "name",
      selectOptions: () => categories,
      vuetifyProps: {
        "no-data-text": i18n.t("no_search_results"),
        "item-value": "id",
        "deletable-chips": true,
        "cache-items": true,
        multiple: true,
        chips: true
      }
    }
  },
  {
    component: FormFieldTypes.autocomplete,
    label: i18n.t("brand.brands"),
    name: "brandSelected",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 3,
      associationValue: "id",
      associationText: "name",
      selectOptions: () => brands,
      vuetifyProps: {
        "no-data-text": i18n.t("no_search_results"),
        "item-value": "id",
        "deletable-chips": true,
        "cache-items": true,
        multiple: true,
        chips: true
      }
    }
  },
  {
    component: FormFieldTypes.autocomplete,
    label: i18n.t("vendors.vendors"),
    name: "vendorSelected",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 3,
      associationValue: "id",
      associationText: "name",
      selectOptions: () => vendors,
      vuetifyProps: {
        "no-data-text": i18n.t("no_search_results"),
        "item-value": "id",
        "deletable-chips": true,
        "cache-items": true,
        multiple: true,
        chips: true
      }
    }
  }
];
