import { Brand } from "@/interfaces/brand";
import {
  ProductCategory,
  ProductCategoryRawData
} from "@/interfaces/productCategoy";
import { Room } from "@/interfaces/room";
import { Vendor } from "@/interfaces/vendor";
import { productViewSearchForm } from "@/metadata/productView";
import {
  Callback,
  DynamicFormComponent,
  FormField
} from "helix-vue-components";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { InventorySearchModel } from "../ProductView.component";
import Template from "./ProductViewSearch.template.vue";

const namespace: string = "ProductModule";

@Component({
  mixins: [Template],
  components: {
    DynamicFormComponent
  }
})
export class ProductViewSearchComponent extends Vue {
  @Action("loadRooms", { namespace })
  public loadRooms!: Callback;
  @Action("loadProductCategories", { namespace })
  public loadProductCategories!: Callback;
  @Action("loadBrands", { namespace })
  public loadBrands!: Callback;
  @Action("loadVendors", { namespace })
  public loadVendors!: Callback;
  @Getter("productCategories", { namespace })
  public productCategories!: ProductCategory[];
  @Getter("rooms", { namespace })
  public rooms!: Room[];
  @Getter("brands", { namespace })
  public brands!: Brand[];
  @Getter("vendors", { namespace })
  public vendors!: Vendor[];

  @Prop({ required: true }) public searchModel!: InventorySearchModel;

  public fieldsConfig: FormField[] = [];
  public model: InventorySearchModel | null = null;
  public loading = false;
  public form: DynamicFormComponent | null = null;

  public async mounted() {
    this.loading = true;
    if (
      !this.rooms.length ||
      !this.productCategories.length ||
      this.brands.length <= 1 ||
      this.vendors.length <= 1
    ) {
      await Promise.all([
        this.loadProductCategories(),
        this.loadRooms(),
        this.loadBrands(),
        this.loadVendors()
      ]);
    }
    this.loading = false;

    this.fieldsConfig = productViewSearchForm(
      this.rooms,
      this.productCategories,
      this.brands,
      this.vendors
    );

    this.$nextTick(() => {
      this.model = { ...this.searchModel };
    });
    this.form = this.$refs["product-view-search"] as DynamicFormComponent;
  }

  public onChange() {
    this.$emit("change", this.form);
  }
}
