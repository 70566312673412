import BarcodeListComponent from "@/components/sharedComponents/print/barcodeList/barcodeList.component";
import InventoryLabelListComponent from "@/components/sharedComponents/print/inventoryLabelList/InventoryLabelList.component";
import TableBatchesListComponent from "@/components/sharedComponents/tableBatchesList/TableBatchesList.component";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { Batch, InventoryBatch, RelatedEntityMin } from "@/interfaces/batch";
import { Product } from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { EventBus, pusherEvents, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { biotrackInventoryReconciliationService } from "@/services/biotrackInventoryReconciliation.service";
import { messagesService } from "@/services/messages.service";
import { productActionService } from "@/services/product.actions.service";
import { productService } from "@/services/product.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  getLabsDataForPrintLabels,
  goToBatchAction
} from "@/utils/batch-actions.utils";
import {
  CallbackPromise,
  DataTablePlaceholderComponent,
  DynamicFormComponent,
  helixQueryStringService,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TableSelectEvent
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import keyBy from "lodash/keyBy";
import unionBy from "lodash/unionBy";
import uniqBy from "lodash/uniqBy";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import InboundTransferBatchDetails from "../BatchTransfer/BatchTransferManager/InboundTransfer/InboundTransferBatchDetails/InboundTransferBatchDetails.component";
import Template from "./ProductView.template.vue";
import { ProductViewSearchComponent } from "./ProductViewSearch/ProductViewSearch.component";
const namespace: string = "ProductModule";

export interface InventorySearchModel {
  selectedRooms?: number[];
  categorySelected?: number[];
  brandSelected?: number[];
  vendorSelected?: number[];
  searchValue?: string;
  itemsPerPage?: number;
  currentPage?: number;
}

@Component({
  mixins: [Template],
  components: {
    DataTablePlaceholderComponent,
    TableBatchesListComponent,
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(productService)
export default class ProductViewComponent extends Vue {
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Getter("products", { namespace })
  public products!: Product[];
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("headers", { namespace })
  public headers!: object[];
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Action("changeIcon", { namespace: "MegaMenuModule" })
  public changeIcon!: Callback;
  @Action("loadInventoryProducts", { namespace })
  public getProductsAction!: Callback;
  @Action("setHeaders", { namespace })
  public setHeadersAction!: Callback;
  @Action("searchProducts", { namespace })
  public searchProducts!: CallbackPromise<void>;
  @Action("cleanProducts", { namespace })
  public cleanProductsAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public multiActionCallbacks = {
    adjust: this.batchAdjust(),
    convert: this.batchConvert(),
    move: () => this.routeToAction("batch-move"),
    split: this.batchSplit(),
    combine: () => this.routeToAction("batch-combine"),
    outbound: this.transferManager("outbound-transfer"),
    quote: this.transferManager("quote"),
    print: this.print(),
    label: this.label()
  };
  public multiActions: TableAction[] = [];
  public clearSetIntervalTimer!: number;
  public paginate = productService.paginationAction();
  public currentPagination: Partial<TablePagination> = {
    currentPage: 1,
    itemsPerPage: 10
  };
  public rowActions: TableAction[] = productActionService.getInventoryViewRowActions();
  public searchValue = "";
  public delayTimer: any;
  public searchModel: InventorySearchModel = {
    selectedRooms: [],
    categorySelected: [],
    brandSelected: [],
    vendorSelected: []
  };
  public productsCopy: Product[] = [];
  public productsData: Product[] = [];
  public selectedItemData: Product[] = [];
  public selectedItemDataCopy: Product[] = [];
  public loadingContent = false;
  public isLoadingPagination = true;
  public selectedItems: number[] = [];
  public actionElements: InventoryBatch[] = [];
  public visiblePopover: { [key: string]: boolean } = {};
  public isLoading = false;
  public isMultiPageSelection = false;
  public percentage: number = 0;
  public isSorting = false;
  protected pageNavConf: any = null;

  protected debounceSync = debounce(this.getBatchSyncingDetails, 500);

  constructor() {
    super();
    this.setHeadersAction("inventory_view");
    this.changeIcon(
      require("@/assets/images/icon_primary_menu_inventory@2x.png")
    );
  }

  public routeToAction(route: string) {
    goToBatchAction(this.actionElements, route);
  }

  public setMultiActions() {
    this.multiActions = productActionService.getInventoryViewMultiActions(
      this.multiActionCallbacks
    );
  }

  public openModal(comp: any, parse?: (data: InventoryBatch[]) => any[]) {
    return () =>
      this.$modals.load(
        comp,
        {
          size: "fit",
          positionY: "top"
        },
        {
          ["itemsSelected"]: parse
            ? parse(this.actionElements)
            : this.actionElements
        }
      );
  }

  public onShowPopover(
    index: number,
    label: string,
    productId: number | string
  ) {
    this.isLoading = true;
    if (this.visiblePopover[`${productId}_${index}_${label}`] === true) {
      this.visiblePopover = {};
    } else if (
      this.visiblePopover[`${productId}_${index}_${label}`] === undefined
    ) {
      this.visiblePopover = {};
      this.visiblePopover[`${productId}_${index}_${label}`] = true;
    }
    this.isLoading = false;
  }

  public async subItemSelected(event: {
    status: boolean;
    id: number;
    total: number;
    item: InventoryBatch;
  }) {
    if (event.status) {
      this.selectedItems = !this.selectedItems.includes(event.id)
        ? [...this.selectedItems, event.id!]
        : [...this.selectedItems];
      this.actionElements.push(event.item);
    } else {
      this.selectedItems = !event.total
        ? this.selectedItems.filter(ids => ids !== event.id!)
        : [...this.selectedItems];

      this.actionElements = this.actionElements.filter(
        batch => batch.batch_uid !== event.item.batch_uid
      );
    }

    this.actionElements = uniqBy(this.actionElements, batch => batch.batch_uid);
  }

  public async onSelection(event: TableSelectEvent) {
    this.multiActions = [];
    if (event.eventItem) {
      if (event.eventItem.status) {
        this.actionElements = unionBy(
          this.actionElements,
          event.eventItem.item.batches.filter(
            (b: InventoryBatch) =>
              b.status !== "OUTGOING" && b.room_quantity_available
          ),
          "batch_uid"
        );
        this.selectedItemData = event.currentSelection.map(
          item => item
        ) as Product[];
        this.selectedItems = event.currentSelection.map(item => item.sku);

        this.selectedItemDataCopy = [
          ...this.selectedItemData,
          ...this.selectedItemDataCopy
        ];
        this.actionElements = uniqBy(
          this.actionElements,
          batch => batch.batch_uid
        );
        this.selectedItems = uniqBy(this.selectedItems, selection => selection);
        this.selectedItemDataCopy = this.selectedItemData = uniqBy(
          this.selectedItemDataCopy,
          selection => selection.sku
        );

        if (this.selectedItemData.length === 0) {
          this.isMultiPageSelection = false;
        } else {
          this.isMultiPageSelection = true;
        }
      } else if (!event.eventItem.status) {
        // @ts-ignore
        event.eventItem!.item.batches.forEach(batchItem => {
          const elementActions = this.actionElements.filter(
            batch => batch.batch_uid !== batchItem.batch_uid
          );

          this.actionElements = cloneDeep(elementActions);
        });

        const productSelection = this.selectedItems.filter(
          product => product !== event.eventItem!.item.sku
        );
        const itemSelected = this.selectedItemData.filter(
          product => product.sku !== event.eventItem!.item.sku
        );
        const itemSelectedCopy = this.selectedItemDataCopy.filter(
          product => product.sku !== event.eventItem!.item.sku
        );

        this.selectedItems = cloneDeep(productSelection);
        this.selectedItemData = cloneDeep(itemSelected);
        this.selectedItemDataCopy = cloneDeep(itemSelectedCopy);
        if (this.selectedItemData.length === 0) {
          this.isMultiPageSelection = false;
        } else {
          this.isMultiPageSelection = true;
        }
      }
    }
    this.setMultiActions();
  }

  public print() {
    return () => {
      EventBus.$emit("print", {
        component: BarcodeListComponent,
        props: { batches: this.parseWithFatherItem(this.actionElements) }
      });
    };
  }

  public batchAdjust() {
    return () => {
      this.$router.push({
        name: "adjust",
        params: { batches: JSON.stringify(this.actionElements) }
      });
    };
  }
  public batchSplit() {
    return () => {
      this.$router.push({
        name: "split",
        params: { batches: JSON.stringify(this.actionElements) }
      });
    };
  }

  public transferManager(type: string) {
    return () => {
      store.dispatch("RouterModule/go", {
        name: "transfer-manager",
        params: {
          batches: this.parseWithFatherItem(this.actionElements),
          type
        }
      });
    };
  }

  public label() {
    return async () => {
      EventBus.$emit("print", {
        component: InventoryLabelListComponent,
        props: {
          batches: await getLabsDataForPrintLabels(
            this.parseWithFatherItem(this.actionElements)
          ),
          labelType: "INVENTORY"
        }
      });
    };
  }

  public batchConvert() {
    return () => {
      store.dispatch("RouterModule/go", {
        name: "convert",
        params: {
          batches: JSON.stringify(this.parseWithFatherItem(this.actionElements))
        }
      });
    };
  }

  public sort(header: TableHeader) {
    this.isSorting = true;
    const clone = cloneDeep(header);
    if (clone.value === "nameQty") {
      clone.value = "name";
    }
    productService.sortQuery(clone);
    this.selectedItems = [];
    this.actionElements = [];
    this.selectedItemData = [];
    this.selectedItemDataCopy = [];
    this.isMultiPageSelection = false;
  }

  public onBarcodeScanned(code: string) {
    this.searchValue = code;
    this.onSearch(code);
    this.search();
  }
  @Watch("products")
  public formatedProducts() {
    const copyProducts = this.products.map(item => {
      const batches = item.batches;
      const searchBatch = (batches as InventoryBatch[]).filter(batch => {
        if (this.searchValue) {
          if (this.searchValue.slice(0, 3) === "BID") {
            return batch.batch_uid === this.searchValue;
          } else {
            return batch.biotrack_traceability_id === this.searchValue;
          }
        }
      });

      return {
        ...item,
        batches: (searchBatch.length && searchBatch) || batches
      };
    });
    this.productsData = copyProducts;
    this.productsCopy = [...this.productsCopy, ...copyProducts];
    this.productsCopy = uniqBy(this.productsCopy, product => product.sku);
  }

  public onSearch(term: string) {
    this.searchValue = term;
    this.search();
  }

  public onClose(update: boolean) {
    if (update || this.searchValue) {
      this.searchValue = "";
      this.search();
    }
  }

  public search() {
    if (
      (this.searchModel.selectedRooms &&
        this.searchModel.selectedRooms.length) ||
      this.searchValue
    ) {
      this.delayTimer();
    } else {
      this.loadingContent = false;
    }
  }

  public unSelectAllItems() {
    if (!this.loading) {
      this.selectedItems = [];
      this.actionElements = [];
      this.selectedItemData = [];
      this.selectedItemDataCopy = [];
      this.isMultiPageSelection = false;
    } else {
      if (!this.isSorting || this.selectedItemData.length) {
        this.loadingContent = false;
        setTimeout(() => {
          this.loadingContent = true;
        }, 50);
      } else {
        this.loadingContent = true;
      }
    }
  }

  public inventoryReconciliationCallback() {
    this.debounceSync();
  }

  public changePagination(pagination: TablePagination) {
    this.isLoadingPagination = false;
    this.currentPagination.itemsPerPage = pagination.itemsPerPage;
    this.currentPagination.currentPage = pagination.currentPage;
    this.delayTimer();
    this.isLoadingPagination = true;
    if (this.isSorting && !this.selectedItemData.length) {
      this.selectedItems = [];
      this.actionElements = [];
      this.selectedItemData = [];
      this.selectedItemDataCopy = [];
      this.isMultiPageSelection = false;
    }
  }

  public async renderPopupModal(
    titleName: string,
    messageName: string,
    descriptionName: string,
    isAcceptButton: boolean,
    isCancleButton: boolean,
    acceptButtonValue?: string,
    syncProgressBar?: boolean
  ) {
    const confirm = (await this.$modals
      .load(
        TraceabilityConfirm,
        { size: "normal", positionX: "center", positionY: "center" },
        {
          modalData: {
            titleAvatar: {
              name: isAcceptButton
                ? "/img/icon_primary_menu_retail@2x.009e06e8.png"
                : "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
              size: "100"
            },
            title: {
              name: String(i18n.t(titleName)).toUpperCase(),
              style: "fontSize:35px ; letter-spacing: 0px;"
            },
            message: {
              name: i18n.t(messageName),
              style: "fontSize:28px ; fontWeight:600"
            },
            description: {
              name: i18n.t(descriptionName),
              style: "fontSize:23px"
            },
            acceptButton: isAcceptButton,
            cancelButton: isCancleButton,
            syncProgressBar,
            acceptButtonValue
          }
        }
      )
      .catch(() => false)) as boolean;
    return confirm;
  }

  public async getBatchSyncingDetails() {
    if (await this.$validator.validateAll()) {
      this.renderPopupModal(
        "traceability_reconciliation.current_inventory",
        "traceability_reconciliation.sync_in_progress",
        "traceability_reconciliation.please_wait_while_the_inventory_is_syncing",
        false,
        false,
        "",
        true
      );
    }
    // to clear any existing setInterval
    if (this.clearSetIntervalTimer) {
      clearInterval(this.clearSetIntervalTimer);
    }
    const response = await biotrackInventoryReconciliationService.getBatchsDetails();
    if (response && response.status === "success") {
      this.clearSetIntervalTimer = setInterval(async () => {
        const synchingStatus = await biotrackInventoryReconciliationService.getBatchSyncingStatus(
          response.data.id
        );
        this.percentage =
          (synchingStatus.data.checked_batches /
            synchingStatus.data.total_batches) *
          100;
        EventBus.$emit("synchingProgressBar", Math.floor(this.percentage));
        if (
          !synchingStatus ||
          synchingStatus.data.errors ||
          synchingStatus.data.status === 1
        ) {
          clearInterval(this.clearSetIntervalTimer);
          // added setTimeout to hold popup to show completion of sync
          setTimeout(() => EventBus.$emit("removePopup", true), 1000);
          const biotrackDetailsData = await biotrackInventoryReconciliationService.getBiotrackdetails(
            response.data.id
          );

          if (biotrackDetailsData.data.data.length !== 0) {
            this.$router.push({
              name: "biotrack-inventory-reconciliation",
              params: {
                detailsData: biotrackDetailsData.data,
                id: response.data.id
              }
            });
          }
          if (synchingStatus.data.errors) {
            messagesService.renderErrorMessage(synchingStatus.data.errors);
          }
        }
      }, 3000);
    }
  }

  protected async created() {
    // @ts-ignore
    this.$barcodeScanner.init(this.onBarcodeScanned);
  }

  protected async mounted() {
    this.setMultiActions();
    this.delayTimer = debounce(async (ref?: DynamicFormComponent) => {
      let form = null;
      if (ref) {
        this.currentPagination.itemsPerPage = 10;
        this.currentPagination.currentPage = 1;
        form = await ref.submit();
        this.searchModel = { ...(form!.currentModel as InventorySearchModel) };
      }

      const params = {
        query: helixQueryStringService.buildStringifiedParams<
          Partial<InventorySearchModel>
        >({
          ...this.searchModel,
          itemsPerPage: this.currentPagination.itemsPerPage,
          currentPage: this.currentPagination.currentPage,
          searchValue: this.searchValue
        })
      };

      this.$router.push({
        name: "products-view",
        params
      });

      const queryfilter: { [key: string]: any } = {
        route: "inventory_view",
        pagination: {
          itemsPerPage: this.currentPagination.itemsPerPage,
          currentPage: this.currentPagination.currentPage
        }
      };

      if (this.searchValue) {
        queryfilter["batches.batch_uid"] = true;
        queryfilter["batches.biotrack_traceability_id"] = true;
        queryfilter["batches.tracking_id"] = true;
        queryfilter.barcode = true;
        queryfilter.value = this.searchValue;
        queryfilter.byText = true;

        if (this.searchModel.categorySelected) {
          queryfilter["category.name"] = this.searchModel.categorySelected;
        }

        if (this.searchModel.brandSelected) {
          queryfilter["brand.name"] = this.searchModel.brandSelected;
        }
      }

      if (this.searchModel.categorySelected && !this.searchValue) {
        queryfilter.product_category = this.searchModel.categorySelected;
      }

      if (this.searchModel.brandSelected && !this.searchValue) {
        queryfilter.brand = this.searchModel.brandSelected;
      }
      if (this.searchModel.vendorSelected && !this.searchValue) {
        queryfilter.vendor = this.searchModel.vendorSelected;
      }

      if (
        this.searchModel.selectedRooms &&
        this.searchModel.selectedRooms.length
      ) {
        queryfilter.byRoom = this.searchModel.selectedRooms;
      }

      queryfilter.dispatcher = "loadInventoryProducts";

      this.searchProducts(queryfilter);
      this.loadingContent = true;
    }, 700);

    if (this.$route.params.query) {
      const params = helixQueryStringService.parseStringifiedParams<
        Partial<InventorySearchModel>
      >(this.$route.params.query);
      this.searchModel = { ...params };
      this.currentPagination.itemsPerPage = params.itemsPerPage;
      this.currentPagination.currentPage = params.currentPage;
      this.searchValue = params.searchValue || "";
      this.delayTimer();
    }

    this.pageNavConf = {
      title: "inventory.current_inventory",
      rightActions: {
        generalActions: () =>
          productActionService.getProductViewGeneralActions(
            this.searchValue,
            this.inventoryReconciliationCallback
          ),
        onSearch: this.onSearch,
        onClose: this.onClose
      },
      secondaryActions: {
        component: ProductViewSearchComponent,
        props: {
          searchModel: this.searchModel
        },
        events: [
          {
            event: "change",
            callback: (ref: DynamicFormComponent) => {
              // This is done to re calculate the height of the page nav based on user interaction.
              this.setPageNav(cloneDeep(this.pageNavConf));
              this.delayTimer(ref);
            }
          }
        ]
      }
    };

    this.setPageNav(this.pageNavConf);

    this.$changes.watch(
      [
        pusherEvents.productTouched,
        pusherEvents.batchTouched,
        pusherEvents.productVariantTouched,
        pusherEvents.batchAction
      ],
      () => {
        this.getProductsAction("inventory_view");
      },
      () => this.productsData.length > 0
    );
    EventBus.$on(
      "stockInventoryView",
      async (inventoryViewProducts: Product[]) => {
        const filterProduct = inventoryViewProducts.map(
          (item: Product) => item.batches
        );
        const filterObject = Object.values(filterProduct[0]);

        const filterBatches = filterObject.filter((item: Batch) => {
          if (this.searchValue.slice(0, 3) === "BID") {
            return item.batch_uid === this.searchValue;
          } else {
            return item.biotrack_traceability_id === this.searchValue;
          }
        });
        if (
          this.searchValue &&
          this.currentRetailSettings.integrations!.biotrack_traceability
            .enable &&
          !filterBatches[0].biotrack_traceability_id &&
          inventoryViewProducts[0].marijuana
        ) {
          await productService.renderSearchErrorModal(
            "biotrack_traceability.search_error",
            "biotrack_traceability.search_error_message",
            "",
            true,
            false,
            "ok",
            "biotrack_traceability.inventory_current_inventory",
            "/img/icon_primary_menu_inventory@2x.9f2161a2.png"
          );
        }
      }
    );
  }

  protected beforeDestroy() {
    // @ts-ignore
    this.$barcodeScanner.destroy();
    // to clear setInterval on routing to differnt page
    if (this.clearSetIntervalTimer) {
      clearInterval(this.clearSetIntervalTimer);
    }
  }

  private parseWithFatherItem(arg: InventoryBatch[]): InventoryBatch[] {
    const products = keyBy(this.productsCopy, "sku");
    arg.forEach(batch => {
      // Assigning brand product to print brand label.
      batch.product_variant.brand = products[batch.product_sku!]
        .brand! as RelatedEntityMin;
    });
    return arg;
  }
}
