import { policyList } from "@/enums/permissions";
import { InventoryBatch, PBSummary } from "@/interfaces/batch";
import { InventorySettings } from "@/interfaces/inventorySettings";
import { store } from "@/internal";
import { BooleanCheck } from "@/types/types";
import differenceInDays from "date-fns/differenceInDays";
import {
  Callback,
  TableCheckboxComponent,
  tableService
} from "helix-vue-components";
import keyBy from "lodash/keyBy";
import toArray from "lodash/toArray";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { TRANSFER_TYPES } from "./../../../interfaces/batchTransferManager";
import Template from "./TableBatchesList.template.vue";

interface TableBatchesList {
  label: string;
  value: string;
  action?: {
    icon: string;
    callback: Callback | null;
  };
}
@Component({
  mixins: [Template],
  components: {
    TableCheckboxComponent
  }
})
export default class TableBatchesListComponent extends Vue {
  @Prop({ default: () => [] })
  public list!: InventoryBatch[];
  @Prop({ required: true })
  public marijuana!: number;
  @Prop({ required: true })
  public bioTrackTraceEnabled!: boolean;
  @Prop({ default: () => [] })
  public selectedSubItems!: InventoryBatch[];
  @Prop({ required: true })
  public item!: string;
  @Prop({ default: null })
  public position!: number | null;
  @Prop({ default: {} })
  public visiblePopover!: { [key: string]: InventoryBatch };
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("currentInventorySettings", { namespace: "AuthModule" })
  public currentInventorySettings!: InventorySettings;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public policyList = policyList;
  public itemsSelected: { [key: string]: InventoryBatch } = {};
  public quantityDetailPos = {
    top: "0",
    left: "0"
  };
  public biotrackTraceabilityId: string = "";
  public fieldsToShow: TableBatchesList[] = [
    {
      label: "current_inventory.batch_id",
      value: "batchUid"
    },
    {
      label: "current_inventory.strain_name",
      value: "strain_name"
    },
    {
      label: "current_inventory.total_thc_cbd",
      value: "totalThcCbd"
    },
    {
      label: "rooms.time_in_location",
      value: "time_in_location",
      action: {
        icon: "fal fa-ellipsis-h",
        callback: null
      }
    },
    {
      label: "current_inventory.room_quantity_formatted",
      value: "room_quantity_formatted",
      action: {
        icon: "fal fa-ellipsis-h",
        callback: null
      }
    },
    {
      label: "current_inventory.total_quantity",
      value: "total_quantity",
      action: {
        icon: "fal fa-ellipsis-h",
        callback: null
      }
    }
  ];
  private outgoingTransfer = TRANSFER_TYPES.OUTGOING;

  @Watch("selectedSubItems")
  public updateItems() {
    if (!this.selectedSubItems.length) {
      this.itemsSelected = {};
    }

    this.itemsSelected = keyBy(
      this.selectedSubItems.filter(batch => batch.product_sku === this.item),
      "batch_uid"
    );
  }

  public showPopover(index: number, label: string) {
    this.$emit("onShowPopover", index, label, this.item);
  }

  public timeInRoom(time: string): string {
    return this.$t("inventory.days", {
      days: differenceInDays(new Date(), new Date(time))
    }).toString();
  }

  public selectItem(event: { status: boolean; item: InventoryBatch }) {
    if (event.status) {
      this.itemsSelected = {
        ...this.itemsSelected,
        [event.item.batch_uid!]: event.item
      };
    } else {
      delete this.itemsSelected[event.item.batch_uid!];
    }

    this.$emit("subItemSelected", {
      status: event.status,
      id: this.item,
      total: toArray(this.itemsSelected).length,
      item: event.item
    });
  }

  public filterRooms(rooms: PBSummary[]) {
    const newRooms: Array<{
      name?: string;
      available_quantity?: number;
      reserved_quantity?: number;
      in_this_inventory_location_since?: string;
      quantity_unit?: string;
    }> = [];
    rooms.map(inventory => {
      const index = newRooms.findIndex(
        room => room.name === inventory.inventory_location.name
      );
      if (index === -1) {
        newRooms.push({
          name: inventory.inventory_location.name,
          available_quantity:
            inventory.batch_fraction_status_type === "AVAILABLE"
              ? inventory.quantity_value
              : 0,
          reserved_quantity:
            inventory.batch_fraction_status_type === "RESERVED"
              ? inventory.quantity_value
              : 0,
          in_this_inventory_location_since:
            inventory.in_this_inventory_location_since,
          quantity_unit: inventory.quantity_unit
        });
      } else {
        newRooms[index] = {
          name: inventory.inventory_location.name,
          available_quantity: newRooms[index].available_quantity
            ? newRooms[index].available_quantity
            : inventory.batch_fraction_status_type === "AVAILABLE"
            ? inventory.quantity_value
            : 0,
          reserved_quantity: newRooms[index].reserved_quantity
            ? newRooms[index].reserved_quantity
            : inventory.batch_fraction_status_type === "RESERVED"
            ? inventory.quantity_value
            : 0,
          in_this_inventory_location_since:
            inventory.in_this_inventory_location_since,
          quantity_unit: inventory.quantity_unit
        };
      }
    });
    return newRooms;
  }

  public dataHeaderStyle(id: number) {
    return this.itemsSelected[id]
      ? {
          background: `rgba(95,95,95,0.6)`,
          color: "white",
          borderTop: `1px solid white`
        }
      : {};
  }

  public iconsStyle(id: number) {
    return { color: this.itemsSelected[id] ? "white" : "#7f7f7f" };
  }

  public labelsStyle(id: number) {
    return {
      color: this.itemsSelected[id] ? "white" : "#09f",
      fontWeight: "bold"
    };
  }

  public formatLabel(
    label: string,
    data: {
      biotrack_traceability_id?: string;
      batch_uid?: string;
      batchUid?: string;
      ndcNumber?: string;
      product?: any;
    }
  ) {
    if (this.bioTrackTraceEnabled && data.biotrack_traceability_id) {
      data.batchUid = data.biotrack_traceability_id;
      data.ndcNumber = data.product.ndc_number ? data.product.ndc_number : "--";
    } else {
      data.batchUid = data.batch_uid;
      data.ndcNumber = data.product.ndc_number ? data.product.ndc_number : "--";
    }
    return tableService.formatText(data, label);
  }

  public showCheckbox(biotrackTraceabilityId: string) {
    if (
      (this.bioTrackTraceEnabled && biotrackTraceabilityId !== "") ||
      this.marijuana === 0
    ) {
      return true;
    }
    return false;
  }

  public showToolTip(biotrackTraceabilityId: string) {
    if (
      this.bioTrackTraceEnabled &&
      biotrackTraceabilityId === "" &&
      this.marijuana === 1
    ) {
      return true;
    }
    return false;
  }
  public goToBatchDetail(id: number) {
    store.dispatch("RouterModule/go", {
      name: "batch-details",
      params: {
        id
      }
    });
  }

  protected mounted() {
    this.itemsSelected = keyBy(
      this.selectedSubItems.filter(batch => batch.product_sku === this.item),
      "batch_uid"
    );

    if (
      this.currentInventorySettings &&
      this.currentInventorySettings.stock_identification_type === "SECONDARY_ID"
    ) {
      this.fieldsToShow.splice(1, 0, {
        label: "current_inventory.secondary_id",
        value: "tracking_id"
      });
    } else if (
      this.currentInventorySettings &&
      this.currentInventorySettings.stock_identification_type === "NDC_NUMBER"
    ) {
      this.fieldsToShow.splice(1, 0, {
        label: "current_inventory.ndc_number",
        value: "ndcNumber"
      });
    }
  }
}
